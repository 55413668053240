@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@600&family=Source+Sans+Pro:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import 'react-slideshow-image/dist/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.index-value {
	z-index: 100000px;
	float: left;
}
